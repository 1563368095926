import React, { createContext, useEffect, useState } from 'react';
import { Receipt } from '../schemas/receipts';

export interface ReceiptContext {
  receipt?: Receipt;
  isLoading: boolean;
  isError: boolean;
}

export const ReceiptContext = createContext<ReceiptContext>({ isLoading: true, isError: false });

interface ReceiptProviderProps {
  receiptId: string;
  children?: React.ReactNode;
}

export const ReceiptProvider: React.FC<ReceiptProviderProps> = ({ receiptId, children }) => {
  const [receipt, setReceipt] = useState<Receipt | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      // TODO: Replace with environment variable for api endpoint when automated deployment is set up
      const response = await fetch(`https://api.payments.test.doshii.co/test/receipts/${receiptId}`);

      if (response.ok) {
        const data = await response.json();
        setReceipt(data);
        setIsLoading(false);
      } else {
        setIsError(true);
      }
    };

    fetchData();
  }, [receiptId]);

  return <ReceiptContext.Provider value={{ receipt, isLoading, isError }}>{children}</ReceiptContext.Provider>;
};
