import { z } from 'zod';

export const extraSchema = z.object({
  name: z.string(),
  value: z.string()
});

export type OrderExtra = z.infer<typeof extraSchema>;

export const taxSchema = z.object({
  name: z.string(),
  value: z.string(),
  taxType: z.string().optional()
});

export type OrderTax = z.infer<typeof taxSchema>;

export const selectedPaymentSchema = z.object({
  amount: z.string(),
  tip: z.string().optional(),
  surcharge: z.string().optional()
});

export type SelectedPayment = z.infer<typeof selectedPaymentSchema>;

export const itemSchema = z.object({
  uuid: z.string(),
  price: z.string(),
  name: z.string(),
  quantity: z.number(),
  isBundle: z.boolean(),
  options: z.array(extraSchema),
  discounts: z.array(extraSchema),
  surcharges: z.array(extraSchema),
  includedItemsOptions: z.array(extraSchema),
  taxes: z.array(taxSchema)
});

export type OrderItem = z.infer<typeof itemSchema>;
