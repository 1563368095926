import React from 'react';
import { Receipt, ReceiptEnum } from '../schemas/receipts';
import { IntegratedReceiptDetailsProps, ReceiptDetailsIntegrated } from './ReceiptDetailsIntegrated';
import { NonIntegratedReceiptDetailsProps, ReceiptDetailsNonIntegrated } from './ReceiptDetailsNonIntegrated';

export const ReceiptDetails = (props: Receipt): React.ReactElement => {
  const { receiptType } = props;

  switch (receiptType) {
    case ReceiptEnum.DIGITAL_RECEIPT: {
      const { orderItems, orderPayments, orderSurcharges, orderDiscounts, subtotal, orderTotal, orderTax } = props;
      const receiptDetailsIntegrated: IntegratedReceiptDetailsProps = {
        orderItems,
        orderPayments,
        orderSurcharges,
        orderDiscounts,
        subtotal,
        orderTotal,
        orderTax
      };
      return <ReceiptDetailsIntegrated {...receiptDetailsIntegrated} />;
    }
    case ReceiptEnum.NON_INTEGRATED_DIGITAL_RECEIPT: {
      const { selectedPayment } = props;
      const receiptDetailsNonIntegrated: NonIntegratedReceiptDetailsProps = {
        selectedPayment
      };
      return <ReceiptDetailsNonIntegrated {...receiptDetailsNonIntegrated} />;
    }
    default:
      return <></>;
  }
};
