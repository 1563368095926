import React from 'react';
import { SelectedPayment } from '../schemas/order-details';
import {
  ReceiptContentDetails,
  ReceiptContentSubheading,
  ReceiptContentText,
  ReceiptContentTextBold,
  ReceiptContentTextBoldTotal
} from '../styles/Receipts/ReceiptContent';

export interface ReceiptYourPaymentProps {
  selectedPayment: SelectedPayment;
  totalPaid: string;
}

export const ReceiptYourPayment = ({ selectedPayment, totalPaid }: ReceiptYourPaymentProps): React.ReactElement => {
  const { amount, surcharge, tip } = selectedPayment;

  return (
    <>
      <ReceiptContentSubheading data-testid="receipt-content-subheading-your-payment">
        Your payment
      </ReceiptContentSubheading>
      <ReceiptContentDetails data-testid="receipt-content-your-payment-purchase">
        <ReceiptContentTextBold data-testid="receipt-content-your-payment-purchase-label">
          Purchase
        </ReceiptContentTextBold>
        <ReceiptContentTextBold
          className="receipt-content__details_bold"
          data-testid="receipt-content-your-payment-purchase-amount"
        >{`$${amount}`}</ReceiptContentTextBold>
      </ReceiptContentDetails>
      {tip && (
        <ReceiptContentDetails data-testid="receipt-content-your-payment-tip">
          <ReceiptContentText data-testid="receipt-content-your-payment-tip-label">Tip</ReceiptContentText>
          <ReceiptContentText data-testid="receipt-content-your-payment-tip-amount">{`+${tip}`}</ReceiptContentText>
        </ReceiptContentDetails>
      )}
      {surcharge && (
        <ReceiptContentDetails data-testid="receipt-content-your-payment-surcharge">
          <ReceiptContentText data-testid="receipt-content-your-payment-surcharge-label">
            Card surcharge
          </ReceiptContentText>
          <ReceiptContentText data-testid="receipt-content-your-payment-surcharge-amount">
            {`+${surcharge}`}
          </ReceiptContentText>
        </ReceiptContentDetails>
      )}
      <br />
      <ReceiptContentDetails data-testid="receipt-content-your-payment-total">
        <ReceiptContentTextBoldTotal data-testid="receipt-content-your-payment-total-label">
          Total paid
        </ReceiptContentTextBoldTotal>
        <ReceiptContentTextBoldTotal data-testid="receipt-content-your-payment-total-amount">
          {`$${totalPaid}`}
        </ReceiptContentTextBoldTotal>
      </ReceiptContentDetails>
    </>
  );
};
