import React from 'react';
import { ReceiptContainer } from '../styles/Receipts/Receipt';
import { ReceiptTitleContainer } from '../styles/Receipts/ReceiptTitle';
import { ReceiptContentContainer } from '../styles/Receipts/ReceiptContent';
import { Receipt, ReceiptEnum } from '../schemas/receipts';
import { ReceiptTitle } from './ReceiptTitle';
import { ReceiptYourPayment } from './ReceiptYourPayment';
import { ReceiptMerchantDetails } from './ReceiptMerchantDetails';
import { ReceiptFooter } from './ReceiptFooter';
import { ReceiptDetails } from './ReceiptDetails';
import { SelectedPayment } from '../schemas/order-details';
import { ReceiptVerifoneData } from './ReceiptVerifoneData';
import { ReceiptYourRefund } from './ReceiptYourRefund';

// TODO Make all the components accessibility compliant

export const ReceiptComponent = (props: Receipt): React.ReactElement => {
  const { merchantDetails, timestamp, amount, receiptType, verifoneData } = props;

  let selectedPayment: SelectedPayment | undefined;
  if (receiptType !== ReceiptEnum.REFUND) {
    ({ selectedPayment } = props);
  }

  return (
    <ReceiptContainer data-testid="receipt-container">
      <ReceiptTitleContainer data-testid="receipt-title-container">
        <ReceiptTitle
          merchantName={merchantDetails.merchantName}
          merchantAbn={merchantDetails.merchantAbn}
          timestamp={timestamp}
          amount={amount}
          receiptType={receiptType}
        />
      </ReceiptTitleContainer>
      <ReceiptContentContainer data-testid="receipt-content-container">
        {receiptType !== ReceiptEnum.REFUND && selectedPayment ? (
          <>
            <ReceiptDetails {...props} />
            <ReceiptYourPayment selectedPayment={selectedPayment} totalPaid={amount} />
          </>
        ) : (
          <ReceiptYourRefund amount={amount} />
        )}
        {verifoneData && <ReceiptVerifoneData verifoneData={verifoneData} />}
        <ReceiptMerchantDetails merchantDetails={merchantDetails} />
        <ReceiptFooter receiptType={receiptType} />
      </ReceiptContentContainer>
    </ReceiptContainer>
  );
};
