import styled from 'styled-components';
// import {} from "styled-components/cssprop";

type marginProps = {
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
};

export const ReceiptContentContainer = styled.div`
  background-color: #ffffff;
  padding: 50px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #3f3f42;
  font-style: normal;

  h1.receipt-content__subheading {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 1em;
  }

  div.receipt-content__details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  div.receipt-content__centered {
    text-align: center;
  }

  p {
    font-size: 14px;
    letter-spacing: 0.015em;
    margin: 0.3em 0 0.3em 0;
    display: inline-block;
  }

  p.receipt-content__details_bold {
    font-weight: 700;
    line-height: 24px;
  }

  p.receipt-content__details_bold_total {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  p.receipt-content___footer {
    font-size: 12.4px;
    line-height: 16.1px;
    margin-left: 13px;
    margin-right: 13px;
  }

  hr {
    border-top: 1px dashed #e1dede;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
  }
`;

export const ReceiptDetailsContainer = styled.div`
  margin-bottom: 20px;
`;

export const ReceiptContentDetails = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ReceiptContentDetailsCentered = styled.div`
  text-align: center;
`;

export const ReceiptContentSubheading = styled.h1`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 1em;
`;

export const ReceiptContentText = styled.p`
  font-size: 14px;
  letter-spacing: 0.015em;
  margin: 0.3em 0 0.3em 0;
  display: inline-block;
  line-height: 20px;
`;

export const ReceiptContentTextBold = styled(ReceiptContentText)`
  font-weight: 700;
  line-height: 24px;
`;

export const ReceiptContentTextBoldTotal = styled(ReceiptContentTextBold)`
  &&& {
    font-size: 18px;
  }
`;

export const ReceiptContentFooter = styled(ReceiptContentText)`
  &&& {
    font-size: 12.4px;
    line-height: 16.1px;
    margin-left: 13px;
    margin-right: 13px;
  }
`;

export const HorizontalRule = styled.hr`
  border-top: 1px dashed #e1dede;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  margin-top: ${(props: marginProps) => props.marginTop ?? '0.5em'};
  margin-bottom: ${(props: marginProps) => props.marginBottom ?? '0.5em'};
  margin-left: ${(props: marginProps) => props.marginLeft ?? 'auto'};
  margin-right: ${(props: marginProps) => props.marginLeft ?? 'auto'};
`;
