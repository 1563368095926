import React from 'react';
import { DEFAULT_RECEIPT_TAX_TEXT } from '../constants/tax';
import { OrderItem, OrderExtra, OrderTax } from '../schemas/order-details';
import {
  HorizontalRule,
  ReceiptContentDetails,
  ReceiptContentSubheading,
  ReceiptContentText,
  ReceiptContentTextBold,
  ReceiptDetailsContainer
} from '../styles/Receipts/ReceiptContent';
import { ReceiptDetailsPayments } from './ReceiptDetailsPayments';

export interface IntegratedReceiptDetailsProps {
  orderItems: OrderItem[];
  orderPayments?: OrderExtra[];
  orderSurcharges?: OrderExtra[];
  orderDiscounts?: OrderExtra[];
  subtotal: string;
  orderTotal: string;
  orderTax?: OrderTax;
}

export const ReceiptDetailsIntegrated = (props: IntegratedReceiptDetailsProps): React.ReactElement => {
  const { orderItems, orderPayments, orderSurcharges, orderDiscounts, subtotal, orderTotal, orderTax } = props;

  return (
    <ReceiptDetailsContainer data-testid="receipt-content-order-details-integrated">
      <ReceiptContentSubheading data-testid="receipt-content-order-details-subheading">
        Order details
      </ReceiptContentSubheading>
      {orderItems.map((item) => {
        const { name, quantity, price, discounts } = item;
        const options = item.options.concat(item.includedItemsOptions);

        return (
          <React.Fragment key={`order-item-${name}`}>
            <ReceiptContentDetails data-testid={`order-details-item-${name}`}>
              <ReceiptContentTextBold
                data-testid={`order-details-item-${name}-text`}
              >{`${quantity} ${name}`}</ReceiptContentTextBold>
              <span>
                {discounts.length > 0 && (
                  <>
                    <img src="images/discount_icon.svg" alt="discount icon" />
                    <span>&nbsp;</span>
                  </>
                )}
                <ReceiptContentTextBold
                  data-testid={`order-details-item-${name}-amount`}
                >{`$${price}`}</ReceiptContentTextBold>
              </span>
            </ReceiptContentDetails>
            {discounts.map((discount) => (
              <ReceiptContentDetails
                key={`${name}-discount-${discount.name}`}
                data-testid={`order-details-item-${name}-discount-${discount.name}`}
              >
                <ReceiptContentText
                  data-testid={`order-details-item-${name}-discount-${discount.name}-text`}
                >{`${discount.name}`}</ReceiptContentText>
                <ReceiptContentText
                  data-testid={`order-details-item-${name}-discount-${discount.name}-amount`}
                >{`${discount.value}`}</ReceiptContentText>
              </ReceiptContentDetails>
            ))}
            {options.map((option) => (
              <ReceiptContentDetails
                key={`${name}-option-item-${option.name}`}
                data-testid={`order-details-item-${name}-option-${option.name}`}
              >
                <ReceiptContentText
                  data-testid={`order-details-item-${name}-option-${option.name}-text`}
                >{`+ ${option.name}`}</ReceiptContentText>
                <ReceiptContentText data-testid={`order-details-item-${name}-option-${option.name}-amount`}>
                  {`${parseFloat(option.value) >= 0 ? '+' : ''}${option.value}`}
                </ReceiptContentText>
              </ReceiptContentDetails>
            ))}
          </React.Fragment>
        );
      })}
      <br />
      <br />
      <ReceiptContentDetails data-testid="receipt-content-order-details-subtotal">
        <ReceiptContentTextBold data-testid="receipt-content-order-details-subtotal-label">
          Subtotal
        </ReceiptContentTextBold>
        <ReceiptContentTextBold data-testid="receipt-content-order-details-subtotal-amount">{`$${subtotal}`}</ReceiptContentTextBold>
      </ReceiptContentDetails>
      {orderDiscounts &&
        orderDiscounts.map((discount) => (
          <ReceiptContentDetails
            key={`order-discount-${discount.name}`}
            data-testid={`order-details-discount-${discount.name}`}
          >
            <span>
              <img
                src="images/discount_icon.svg"
                alt="discount icon"
                data-testid={`order-details-discount-${discount.name}-icon`}
              />
              <span>&nbsp;&nbsp;</span>
              <ReceiptContentText
                data-testid={`order-details-discount-${discount.name}-label`}
              >{`${discount.name}`}</ReceiptContentText>
            </span>
            <ReceiptContentText
              data-testid={`order-details-discount-${discount.name}-amount`}
            >{`-$${discount.value}`}</ReceiptContentText>
          </ReceiptContentDetails>
        ))}
      {orderSurcharges &&
        orderSurcharges.map((surcharge) => (
          <ReceiptContentDetails
            key={`order-surcharge-${surcharge.name}`}
            data-testid={`order-details-surcharge-${surcharge.name}`}
          >
            <ReceiptContentText
              data-testid={`order-details-surcharge-${surcharge.name}-label`}
            >{`${surcharge.name}`}</ReceiptContentText>
            <ReceiptContentText
              data-testid={`order-details-surcharge-${surcharge.name}-amount`}
            >{`$${surcharge.value}`}</ReceiptContentText>
          </ReceiptContentDetails>
        ))}
      <HorizontalRule marginTop="10px" marginBottom="10px" />
      <ReceiptContentDetails data-testid="receipt-content-order-details-total">
        <ReceiptContentTextBold data-testid="receipt-content-order-details-total-label">
          Order total
        </ReceiptContentTextBold>
        <ReceiptContentTextBold data-testid="receipt-content-order-details-total-amount">{`$${orderTotal}`}</ReceiptContentTextBold>
      </ReceiptContentDetails>
      <ReceiptContentDetails data-testid="receipt-content-order-details-tax">
        <ReceiptContentText data-testid="receipt-content-order-details-tax-text">
          {DEFAULT_RECEIPT_TAX_TEXT}
        </ReceiptContentText>
        {orderTax && (
          <ReceiptContentText data-testid="receipt-content-order-details-tax-amount">{`$${orderTax.value}`}</ReceiptContentText>
        )}
      </ReceiptContentDetails>
      <HorizontalRule marginTop="10px" marginBottom="10px" />
      {orderPayments && orderPayments.length > 0 && (
        <>
          <br />
          <ReceiptDetailsPayments orderPayments={orderPayments} />
        </>
      )}
    </ReceiptDetailsContainer>
  );
};
