import React from 'react';
import { DEFAULT_RECEIPT_TAX_TEXT } from '../constants/tax';
import { SelectedPayment } from '../schemas/order-details';
import {
  ReceiptContentSubheading,
  ReceiptContentDetails,
  ReceiptContentText,
  ReceiptContentTextBold,
  ReceiptDetailsContainer,
  HorizontalRule
} from '../styles/Receipts/ReceiptContent';

export interface NonIntegratedReceiptDetailsProps {
  selectedPayment: SelectedPayment;
}

export const ReceiptDetailsNonIntegrated = (props: NonIntegratedReceiptDetailsProps): React.ReactElement => {
  const { selectedPayment } = props;

  return (
    <ReceiptDetailsContainer data-testid="receipt-content-order-details-non-integrated">
      <ReceiptContentSubheading data-testid="receipt-content-subheading-order-details">
        Order details
      </ReceiptContentSubheading>
      <ReceiptContentDetails data-testid="receipt-content-order-details-custom-amount">
        <ReceiptContentTextBold data-testid="receipt-content-order-details-custom-amount-label">
          Custom amount
        </ReceiptContentTextBold>
        <ReceiptContentTextBold data-testid="receipt-content-order-details-custom-amount-value">{`$${selectedPayment.amount}`}</ReceiptContentTextBold>
      </ReceiptContentDetails>
      <HorizontalRule marginTop="20px" marginBottom="10px" />
      <ReceiptContentDetails data-testid="receipt-content-order-details-subtotal">
        <ReceiptContentTextBold data-testid="receipt-content-order-details-subtotal-label">
          Subtotal
        </ReceiptContentTextBold>
        <ReceiptContentTextBold data-testid="receipt-content-order-details-subtotal-amount">{`$${selectedPayment.amount}`}</ReceiptContentTextBold>
      </ReceiptContentDetails>
      <HorizontalRule marginTop="10px" marginBottom="10px" />
      <ReceiptContentDetails data-testid="receipt-content-order-details-total">
        <ReceiptContentTextBold data-testid="receipt-content-order-details-total-label">
          Order total
        </ReceiptContentTextBold>
        <ReceiptContentTextBold data-testid="receipt-content-order-details-total-amount">{`$${selectedPayment.amount}`}</ReceiptContentTextBold>
      </ReceiptContentDetails>
      <ReceiptContentDetails data-testid="receipt-content-order-details-tax">
        <ReceiptContentText data-testid="receipt-content-order-details-tax-text">
          {DEFAULT_RECEIPT_TAX_TEXT}
        </ReceiptContentText>
      </ReceiptContentDetails>
      <HorizontalRule marginTop="15px" marginBottom="10px" />
    </ReceiptDetailsContainer>
  );
};
