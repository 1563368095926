import { z } from 'zod';
import { merchantDetailsSchema } from './merchant';
import { extraSchema, selectedPaymentSchema, itemSchema, taxSchema } from './order-details';
import { verifoneDataSchema } from './verifone';

export enum IntegratedReceiptEnum {
  DIGITAL_RECEIPT = 'DIGITAL_RECEIPT'
}

export enum NonIntegratedReceiptEnum {
  NON_INTEGRATED_DIGITAL_RECEIPT = 'NON_INTEGRATED_DIGITAL_RECEIPT'
}

enum RefundReceiptEnum {
  REFUND = 'REFUND'
}

export type ReceiptType = IntegratedReceiptEnum | NonIntegratedReceiptEnum | RefundReceiptEnum;

export const ReceiptEnum = {
  ...IntegratedReceiptEnum,
  ...NonIntegratedReceiptEnum,
  ...RefundReceiptEnum
};

export const integratedReceiptSchema = z.object({
  merchantDetails: merchantDetailsSchema,
  timestamp: z.string(),
  amount: z.string(),
  subtotal: z.string(),
  orderTotal: z.string(),
  orderTax: taxSchema.optional(),
  receiptType: z.nativeEnum(IntegratedReceiptEnum),
  selectedPayment: selectedPaymentSchema,
  orderItems: z.array(itemSchema).min(1),
  orderPayments: z.array(extraSchema),
  orderSurcharges: z.array(extraSchema),
  orderDiscounts: z.array(extraSchema),
  verifoneData: verifoneDataSchema.optional()
});

export const nonIntegratedReceiptSchema = z.object({
  merchantDetails: merchantDetailsSchema,
  timestamp: z.string(),
  amount: z.string(),
  receiptType: z.nativeEnum(NonIntegratedReceiptEnum),
  selectedPayment: selectedPaymentSchema,
  verifoneData: verifoneDataSchema.optional()
});

export const refundReceiptSchema = z.object({
  merchantDetails: merchantDetailsSchema,
  timestamp: z.string(),
  amount: z.string(),
  receiptType: z.nativeEnum(RefundReceiptEnum),
  verifoneData: verifoneDataSchema.optional()
});

export type Receipt =
  | z.infer<typeof integratedReceiptSchema>
  | z.infer<typeof nonIntegratedReceiptSchema>
  | z.infer<typeof refundReceiptSchema>;
