import styled from 'styled-components';

export const ReceiptContainer = styled.div`
  width: 100%;
  max-width: 360px;
  min-width: 300px;
  height: 100%;
  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
`;
