const getDateSuffix = (date: number): string => {
  if (date > 3 && date < 21) return 'th';
  switch (date % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const formatDateTime = (dateTime: string): string => {
  const receiptDate = new Date(dateTime);
  const time = receiptDate.toLocaleTimeString('en-au', { hour: 'numeric', minute: '2-digit', hour12: true });
  const date = receiptDate.getDate();
  const monthAndYear = receiptDate.toLocaleString('en-au', { month: 'long', year: 'numeric' });
  return `${time} on ${date}${getDateSuffix(date)} ${monthAndYear}`;
};
