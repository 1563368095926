import React from 'react';
import { OrderExtra } from '../schemas/order-details';
import {
  ReceiptContentTextBold,
  ReceiptContentDetails,
  ReceiptContentSubheading
} from '../styles/Receipts/ReceiptContent';

export interface ReceiptDetailsPaymentsProps {
  orderPayments: OrderExtra[];
}

export const ReceiptDetailsPayments = (props: ReceiptDetailsPaymentsProps): React.ReactElement => {
  const { orderPayments } = props;

  return (
    <>
      <ReceiptContentSubheading data-testid="receipt-content-payments-subheading">Payments</ReceiptContentSubheading>
      {orderPayments.map((payment, index) => (
        <ReceiptContentDetails key={`payments-${index + 1}`} data-testid={`receipt-content-payments-${index + 1}`}>
          <ReceiptContentTextBold data-testid={`receipt-content-payments-${index + 1}-label`}>
            {payment.name}
          </ReceiptContentTextBold>
          <ReceiptContentTextBold
            data-testid={`receipt-content-payments-${index + 1}-amount`}
          >{`$${payment.value}`}</ReceiptContentTextBold>
        </ReceiptContentDetails>
      ))}
    </>
  );
};
