import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { ReceiptProvider } from './providers/ReceiptProvider';
import { PageRouter } from './components/PageRouter';

const Container = styled.div`
  background-color: #e5e5e5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

interface AppParams {
  receiptId: string;
}

const App = (): React.ReactElement => {
  const { receiptId } = useParams<keyof AppParams>() as AppParams;

  return (
    <ReceiptProvider receiptId={receiptId}>
      <Container data-testid="app-container">
        <PageRouter />
      </Container>
    </ReceiptProvider>
  );
};

export default App;
