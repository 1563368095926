import styled from 'styled-components';

export const ReceiptTitleContainer = styled.div`
  background-color: #3f3f42;
  min-height: 250px;
  display: flex;
  text-align: center;
  color: white;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px 20px 30px;
`;

export const ReceiptTitleLogo = styled.img`
  height: 60px;
  width: 60px;
`;

export const ReceiptTitleCompanyName = styled.h2`
  margin-bottom: 0;
  font-weight: 500;
`;

export const ReceiptTitleDateTime = styled.p`
  margin-top: 0.7rem;
`;

export const ReceiptTitleAmount = styled.p`
  font-size: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
  text-decoration: underline #ffda4a 2px;
  text-underline-offset: 10px;
`;

export const ReceiptTitleType = styled.p`
  margin-top: 1.5rem;
  font-weight: 500;
`;
