import React from 'react';
import { VerifoneData } from '../schemas/verifone';
import { ReceiptContentDetails, ReceiptContentText } from '../styles/Receipts/ReceiptContent';

interface ReceiptVerifoneDataProps {
  verifoneData: VerifoneData;
}

export const ReceiptVerifoneData = ({ verifoneData }: ReceiptVerifoneDataProps): React.ReactElement => {
  const {
    terminalId,
    auth,
    stan,
    rrn,
    maskedCardNumber,
    accountType,
    paymentType,
    psn,
    eftposType,
    aid,
    tvr,
    atc,
    approval,
    crType
  } = verifoneData;

  return (
    <>
      <br />
      <ReceiptContentDetails data-testid="receipt-verifone-tid-auth">
        <ReceiptContentText data-testid="receipt-verifone-tid">{terminalId}</ReceiptContentText>
        <ReceiptContentText data-testid="receipt-verifone-auth">{auth}</ReceiptContentText>
      </ReceiptContentDetails>
      {(stan || rrn) && (
        <ReceiptContentDetails data-testid="receipt-verifone-stan-rrn">
          <ReceiptContentText data-testid="receipt-verifone-stan">{stan}</ReceiptContentText>
          <ReceiptContentText data-testid="receipt-verifone-rrn">{rrn}</ReceiptContentText>
        </ReceiptContentDetails>
      )}
      {(maskedCardNumber || accountType) && (
        <ReceiptContentDetails data-testid="receipt-verifone-card-accountType">
          <ReceiptContentText data-testid="receipt-verifone-card">{maskedCardNumber}</ReceiptContentText>
          <ReceiptContentText data-testid="receipt-verifone-accountType">{accountType}</ReceiptContentText>
        </ReceiptContentDetails>
      )}
      {(paymentType || psn) && (
        <ReceiptContentDetails data-testid="receipt-verifone-paymentType-psn">
          <ReceiptContentText data-testid="receipt-verifone-paymentType">{paymentType}</ReceiptContentText>
          <ReceiptContentText data-testid="receipt-verifone-psn">{psn}</ReceiptContentText>
        </ReceiptContentDetails>
      )}
      {(eftposType || aid) && (
        <ReceiptContentDetails data-testid="receipt-verifone-eftposType-aid">
          <ReceiptContentText data-testid="receipt-verifone-eftposType">{eftposType}</ReceiptContentText>
          <ReceiptContentText data-testid="receipt-verifone-aid">{aid}</ReceiptContentText>
        </ReceiptContentDetails>
      )}
      {(tvr || atc) && (
        <ReceiptContentDetails data-testid="receipt-verifone-tvr-atc">
          <ReceiptContentText data-testid="receipt-verifone-tvr">{tvr}</ReceiptContentText>
          <ReceiptContentText data-testid="receipt-verifone-atc">{atc}</ReceiptContentText>
        </ReceiptContentDetails>
      )}
      <br />
      {(approval || crType) && (
        <ReceiptContentDetails data-testid="receipt-verifone-approval-cr">
          <ReceiptContentText data-testid="receipt-verifone-approval">{approval}</ReceiptContentText>
          <ReceiptContentText data-testid="receipt-verifone-cr">{crType}</ReceiptContentText>
        </ReceiptContentDetails>
      )}
    </>
  );
};
