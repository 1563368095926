import { z } from 'zod';

export const verifoneDataSchema = z.object({
  terminalId: z.string(),
  auth: z.string(),
  stan: z.string(),
  rrn: z.string(),
  maskedCardNumber: z.string(),
  accountType: z.string(),
  paymentType: z.string(),
  psn: z.string(),
  eftposType: z.string(),
  aid: z.string(),
  tvr: z.string(),
  atc: z.string(),
  approval: z.string(),
  crType: z.string()
});

export type VerifoneData = z.infer<typeof verifoneDataSchema>;
