import React from 'react';
import { MerchantDetails } from '../schemas/merchant';
import {
  HorizontalRule,
  ReceiptContentDetailsCentered,
  ReceiptContentText,
  ReceiptContentTextBold
} from '../styles/Receipts/ReceiptContent';

interface ReceiptMerchantDetailsProps {
  merchantDetails: MerchantDetails;
}

export const ReceiptMerchantDetails = ({ merchantDetails }: ReceiptMerchantDetailsProps): React.ReactElement => {
  const { merchantName, merchantAddress, merchantAbn } = merchantDetails;

  return (
    <ReceiptContentDetailsCentered data-testid="receipt-content-merchant-details">
      <HorizontalRule marginTop="20px" marginBottom="20px" />
      <ReceiptContentTextBold data-testid="receipt-content-merchant-details-name">
        {merchantName}
      </ReceiptContentTextBold>
      <br />
      <ReceiptContentText data-testid="receipt-content-merchant-details-address">{merchantAddress}</ReceiptContentText>
      {merchantAbn && (
        <>
          <br />
          <ReceiptContentTextBold data-testid="receipt-content-merchant-details-abn">
            ABN {merchantAbn}
          </ReceiptContentTextBold>
        </>
      )}
      <HorizontalRule marginTop="20px" marginBottom="20px" />
    </ReceiptContentDetailsCentered>
  );
};
