import React, { useContext } from 'react';
import { ReceiptContext } from '../providers/ReceiptProvider';
import { ReceiptComponent } from './Receipt';

export const PageRouter = (): React.ReactElement => {
  const { receipt, isError } = useContext(ReceiptContext);

  if (receipt) {
    return <ReceiptComponent {...receipt} />;
  }

  if (isError) {
    return <div data-testid="receipt-data-notfound">Receipt Id not found</div>;
  }

  return <div data-testid="receipt-data-loading">Loading</div>;
};
