import React from 'react';
import {
  ReceiptContentDetails,
  ReceiptContentSubheading,
  ReceiptContentTextBold,
  ReceiptContentTextBoldTotal
} from '../styles/Receipts/ReceiptContent';

export interface ReceiptYourRefundProps {
  amount: string;
}

export const ReceiptYourRefund = ({ amount }: ReceiptYourRefundProps): React.ReactElement => {
  return (
    <>
      <ReceiptContentSubheading data-testid="receipt-content-subheading-your-refund">
        Your refund
      </ReceiptContentSubheading>
      <ReceiptContentDetails data-testid="receipt-content-refund-amount">
        <ReceiptContentTextBold data-testid="receipt-content-refund-amount-label">Refund amount</ReceiptContentTextBold>
        <ReceiptContentTextBold data-testid="receipt-content-refund-amount-value">{`$${amount}`}</ReceiptContentTextBold>
      </ReceiptContentDetails>
      <br />
      <br />
      <ReceiptContentDetails data-testid="receipt-content-refund-total">
        <ReceiptContentTextBoldTotal data-testid="receipt-content-refund-total-label">
          Total
        </ReceiptContentTextBoldTotal>
        <ReceiptContentTextBoldTotal data-testid="receipt-content-refund-total-value">{`$${amount}`}</ReceiptContentTextBoldTotal>
      </ReceiptContentDetails>
    </>
  );
};
