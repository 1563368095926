import React from 'react';
import { ReceiptType, ReceiptEnum } from '../schemas/receipts';
import {
  ReceiptTitleAmount,
  ReceiptTitleCompanyName,
  ReceiptTitleDateTime,
  ReceiptTitleLogo,
  ReceiptTitleType
} from '../styles/Receipts/ReceiptTitle';
import { formatDateTime } from '../utils/datetime/datetime.utils';

export interface ReceiptTitleProps {
  merchantName: string;
  merchantAbn?: string;
  timestamp: string;
  amount: string;
  receiptType: ReceiptType;
}

const getTypeOfReceipt = (type: ReceiptType, merchantAbn?: string): string => {
  if (type === ReceiptEnum.REFUND) {
    return 'Refund receipt';
  }

  if (merchantAbn) {
    return 'Digital tax invoice';
  }
  return 'Digital receipt';
};

export const ReceiptTitle = (props: ReceiptTitleProps): React.ReactElement => {
  const { merchantName, merchantAbn, timestamp, amount, receiptType } = props;

  return (
    <>
      <ReceiptTitleLogo src="images/cba_logo.svg" alt="CBA Logo" data-testid="receipt-title-logo" />
      <ReceiptTitleCompanyName data-testid="receipt-title-company-name">{merchantName}</ReceiptTitleCompanyName>
      <ReceiptTitleDateTime data-testid="receipt-title-date-time">{formatDateTime(timestamp)}</ReceiptTitleDateTime>
      <ReceiptTitleAmount data-testid="receipt-title-amount">{`$${amount}`}</ReceiptTitleAmount>
      <ReceiptTitleType data-testid="receipt-title-type">{getTypeOfReceipt(receiptType, merchantAbn)}</ReceiptTitleType>
    </>
  );
};
