import React from 'react';
import { ReceiptEnum, ReceiptType } from '../schemas/receipts';
import { ReceiptContentDetailsCentered, ReceiptContentFooter } from '../styles/Receipts/ReceiptContent';

export interface ReceiptFooterProps {
  receiptType: ReceiptType;
}

const getFooterMessage = (type: ReceiptType): string => {
  switch (type) {
    case ReceiptEnum.DIGITAL_RECEIPT:
      return 'Please retain this receipt for tax purposes';
    case ReceiptEnum.NON_INTEGRATED_DIGITAL_RECEIPT:
    case ReceiptEnum.REFUND:
    default:
      return 'Thank you';
  }
};

export const ReceiptFooter = (props: ReceiptFooterProps): React.ReactElement => {
  const { receiptType } = props;
  const footerMessage = getFooterMessage(receiptType);

  return (
    <ReceiptContentDetailsCentered data-testid="receipt-content-footer">
      <ReceiptContentFooter data-testid="receipt-content-footer-text">{footerMessage}</ReceiptContentFooter>
    </ReceiptContentDetailsCentered>
  );
};
